import React from "react"
import axios from "axios"
import moment from "moment"
import Moment from "react-moment"
import { navigate } from "gatsby"

import SEO from "../../components/seo"
import Layout from "../../components/layout"

import bookingform from "../../queries/bookingform"

import "../../styles/cart.css"

/**
 *
 *
 * @class Cart
 * @extends {React.Component}
 */
class Cart extends React.Component {
  GRAPHQL_API = process.env.GATSBY_PIH_URL

  tours = []
  options = []

  // Declaring shared variables
  tourCode
  tourDate
  bookingRef
  token

  // Initialising the state
  state = {
    tours: [],
    options: [],
    cartTourPrice: 0,
    cartOptionPrice: 0,
    cost: 0,
  }

  /**
   * Creates an instance of Cart.
   * This method binds the component methods to this and sets up member variables from local storage
   * @param {*} props
   * @memberof Cart
   */
  constructor(props) {
    super(props)
    this.bindMethods()
    this.setPropsFromLocalStorage()
  }

  /**
   * This method pulls the data from local storage and initialises memeber variables
   * This method inialises jwt token, tour code, tour date and booking reference
   * @memberof Cart
   */
  setPropsFromLocalStorage() {
    this.token =
      typeof window !== "undefined" && window.localStorage.getItem("token")
    this.tourCode =
      typeof window !== "undefined" && window.localStorage.getItem("tourCode")
    this.tourDate =
      typeof window !== "undefined" && window.localStorage.getItem("tourDate")
    this.bookingRef =
      typeof window !== "undefined" && window.localStorage.getItem("bookingRef")
    this.expiresAt =
      typeof window !== "undefined" && window.localStorage.getItem("expiresAt")
  }

  /**
   * This method binds this to component functions
   * Unsure whether this is required
   * @memberof Cart
   */
  bindMethods() {
    this.submitForm = this.submitForm.bind(this)
    this.processData = this.processData.bind(this)
    this.getTotalTourPrice = this.getTotalTourPrice.bind(this)
    this.getPickupsDropoffs = this.getPickupsDropoffs.bind(this)
    this.getPassengerDetails = this.getPassengerDetails.bind(this)
    this.getTotalOptionPrice = this.getTotalOptionPrice.bind(this)
    this.clearCart = this.clearCart.bind(this)
  }

  componentDidMount() {
    if (!this.bookingRef) {
      // TODO: Empty cart. Just return?
      return
    }

    // expiresAt in past means the booking hold is dropped by PIH.
    if (moment(this.expiresAt) <= moment()) {
      this.clearCart()
      this.setPropsFromLocalStorage()
      console.log("Cleared cart due to expiry")
      return
    }

    const query = bookingform.getCart(this.bookingRef)

    axios.post(this.GRAPHQL_API, { query }, {}).then(response => {
      if (response.data.data.getCart == null) {
        // return // XXX
      }

      this.state.cost = response.data.data.getCart.cost
      let tours = []
      let options = []

      console.log("cart.js 1", response)
      response.data.data.getCart.cartItems.forEach(element => {
        // Initialise tours and options CartItem arrays later to be merged together
        // If an option is found to be a child of a tour CartItem then it will be merged to the parent item
        switch (element.itemType) {
          case "TOUR":
            tours.push(element)
            break
          case "OPTION":
            options.push(element)
            break
        }
      })

      console.log("cart.js 2", tours, options)

      // Process tours and options CartItem arrays later to be merged together
      // Get the passenger details to enable/disable edit passenger details button
      this.processData(tours, options)
      this.getPassengerDetails()
    })
  }

  /**
   * This method iterates through the cart items
   * and fetches the tour and option information and merges them if there is a relation
   * @param {Object} tours - JSON tours cart item
   * @param {Object} options - JSON options cart item
   * @memberof Cart
   */
  processData(tours, options) {
    let cartTourPrice = 0
    let cartOptionPrice = 0

    // XXX Agent code
    const query = bookingform.getBookingForm(76, this.tourCode, this.tourDate)

    for (let index = 0; index < tours.length; index++) {
      axios.post(this.GRAPHQL_API, { query }, {}).then(resp => {
        console.log("process data cart.js>")
        console.log(resp)

        // Set the tour data
        tours[index]["tourData"] = resp.data
        tours[index]["optionData"] = []

        // get the total tour price
        tours[index]["totalTourPrice"] = this.getTotalTourPrice(tours[index])

        // get the pickup and drop off locations
        tours[index]["pickUpLocation"] = this.getPickupsDropoffs(
          tours[index]["pickupID"],
          tours[index]["tourData"].data.getPickups
        )
        tours[index]["dropOffLocation"] = this.getPickupsDropoffs(
          tours[index]["dropoffID"],
          tours[index]["tourData"].data.getDropoffs
        )

        cartTourPrice = cartTourPrice + tours[index]["totalTourPrice"]

        // Iterate through the options booked
        // Find the tour associated with the booked option and merge them together
        options.forEach(option => {
          const index = tours.findIndex(tour => tour.id === option.itemParentID)
          const optionIndex = tours[index].tourData.data.getOptions.findIndex(
            opt => opt.id === option.itemID
          )

          // get the total option price and description
          option["totalTourOptionPrice"] = this.getTotalOptionPrice(
            option,
            tours[index].tourData.data.getOptions[optionIndex]
          )
          option["description"] =
            tours[index].tourData.data.getOptions[optionIndex].description
          tours[index]["optionData"].push(option)
          cartOptionPrice = cartOptionPrice + option["totalTourOptionPrice"]
        })

        this.setState({ tours: tours })
        this.setState({ cartTourPrice })
        this.setState({ cartOptionPrice })

        setTimeout(() => {
          this.forceUpdate()
        }, 0)
      })
    }
  }

  /**
   * This method calculates the total price from only options and not from tour booking
   * @param {Object} tour - JSON object containing tour details
   * @returns {number} totalPrice - total price calculated from only options and not from tour booking
   * @memberof Cart
   */
  getTotalOptionPrice(option, tourOption) {
    let totalPrice = 0
    tourOption.prices.forEach(pricing => {
      switch (pricing.passengerType) {
        case "ADULT":
          // totalPrice = option.numAdults * (totalPrice + pricing.price)
          totalPrice += option.numAdults * pricing.price
          break
        case "CHILD":
          // totalPrice = option.numChildren * (totalPrice + pricing.price)
          totalPrice += option.numChildren * pricing.price
          break
        case "INFANT":
          // totalPrice = option.numInfants * (totalPrice + pricing.price)
          totalPrice += option.numChildren * pricing.price
          break
      }
    })
    return totalPrice
  }

  /**
   * This method calculates the total price from only tour booking and not from options
   * @param {Object} tour - JSON object containing tour details
   * @returns {number} totalPrice - total price calculated from only tour booking and not from options
   * @memberof Cart
   */
  getTotalTourPrice(tour) {
    let totalPrice = 0
    tour.tourData.data.getTourPricing.forEach(pricing => {
      switch (pricing.passengerType) {
        case "ADULT":
          // totalPrice = tour.numAdults * (totalPrice + pricing.price)
          totalPrice += tour.numAdults * pricing.price
          break
        case "CHILD":
          // totalPrice = tour.numChildren * (totalPrice + pricing.price)
          totalPrice += tour.numChildren * pricing.price
          break
        case "INFANT":
          // totalPrice = tour.numInfants * (totalPrice + pricing.price)
          totalPrice += tour.numInfants * pricing.price
          break
      }
    })
    return totalPrice
  }

  /**
   * This method is used to fetch the pick up and drop off location names from IDs
   * @param {String} id - pick up/drop off ID
   * @param {Array} locations - all pick up/drop off locations available
   * @returns {String} description - description of the location name
   * @memberof Cart
   */
  getPickupsDropoffs(id, locations) {
    const index = locations.findIndex(location => location.id == id)
    if (index === -1 || locations.length === 0) {
      // Location not found
      console.log(
        "cart.js -> getPickupsDropoffs -> not found",
        id,
        index,
        locations
      )
      return ""
    }
    return locations[index].description
  }

  /**
   * This method fetches the passenger details given a booking reference
   * This is used to show/hide the edit passenger details button
   * @memberof Cart
   */
  getPassengerDetails() {
    const query = bookingform.getPassengerDetails(this.bookingRef)
    const headers = { Authorization: `Bearer ${this.token}` }

    axios.post(this.GRAPHQL_API, { query }, { headers }).then(response => {
      if (response.data.data && response.data.data.getPassengers) {
        this.setState({ passengerExists: true })
      }
    })
  }

  clearCart() {
    typeof window !== "undefined" && window.localStorage.clear()

    this.setState({
      tours: [],
      options: [],
      cartTourPrice: 0,
      cartOptionPrice: 0,
      cost: 0,
    })
  }

  /**
   * This is used to navigate to the either checkout or passenger details page
   * If the passenger details are filled navigate to /checkout or /my-details
   * @memberof Cart
   */
  submitForm = () => {
    if (this.state.passengerExists) {
      navigate("/cart/checkout")
    } else {
      navigate("/cart/my-details")
    }
  }

  render() {
    return (
      <>
        <SEO title="Booking Cart | New Zealand Bus Tours - awesomeNZ" />
        <Layout
          layoutType="typography TourPage"
          slideshow={
            <ul class="slideshow-slides">
              <li class="slideshow-slide first">
                <img
                  src={require("../../images/slideshow/hobbit-hole.jpg")}
                  alt=""
                  class="first last image"
                />
              </li>
            </ul>
          }
          hasSliderHandles={false}
          hasSliderBlurSmall={true}
          content={
            <div id="row" class="fourteen clearfix">
              <div class="title-div clearfix">
                <h1>Your Cart</h1>
                <h3>
                  Great! You're just a few steps away from booking your
                  awesomeNZ experience
                </h3>
              </div>
              <div id="Overview" class="clearfix">
                <div id="Content" class="clearfix">
                  <div id="ContentTab">
                    {/* Content from awesomeNZ tour page goes here */}
                    <div className="CartDetail">
                      <div className="ResCart UnconfirmedBookings">
                        {this.state?.tours?.length == 0 && (
                          <p>No tours in cart.</p>
                        )}
                        {this.state?.tours?.map((tour, index) => {
                          return (
                            <React.Fragment key={index}>
                              <div className="Item Head Tour Holding odd  Active">
                                <span className="Description">
                                  {tour.tour.description}
                                  <br />{" "}
                                  <span className="departTime">
                                    {tour.departureDate}
                                  </span>
                                </span>
                                <React.Fragment>
                                  <span className="Composition">
                                    {tour.numAdults !== 0 ? (
                                      <React.Fragment>
                                        {tour.numAdults}x Flexi&nbsp;
                                      </React.Fragment>
                                    ) : (
                                      ""
                                    )}
                                    {tour.numChildren !== 0 ? (
                                      <React.Fragment>
                                        {tour.numChildren}x Children&nbsp;
                                      </React.Fragment>
                                    ) : (
                                      ""
                                    )}
                                    {tour.numInfants !== 0 ? (
                                      <React.Fragment>
                                        {tour.numInfants}x Infants&nbsp;
                                      </React.Fragment>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </React.Fragment>
                                <span className="Price">${tour.cost}</span>
                                <span className="Status"> Holding </span>
                                <span className="Actions">
                                  {/* TODO: onClick = clear cart */}
                                  <a
                                    className="Cancel"
                                    title="Cancel/remove this item."
                                    onClick={this.clearCart}
                                  >
                                    X
                                  </a>
                                </span>
                              </div>
                              {/*
                <div
                  className="Item Head Sector Holding even TourComp IH304525324"
                  id="IH304525325"
                  style={{ display: "block" }}
                >
                  <span className="Description">
                    Travel from {tour?.pickUpLocation} to{" "}
                    {tour?.tourData?.data?.getTour?.destination?.name}
                    <br />{" "}
                    <span className="departTime">18-Mar-2020 7:05 am </span>
                  </span>
                  <span className="Composition">1x Flexi&nbsp;</span>
                  <span className="Price"> </span>
                  <span className="Status"> Holding </span>
                  <span className="Actions"></span>
                </div>
                {tour.tourData?.data?.getOptions
                  .filter(option => option.type !== "BOOKING_REQUIRED")
                  .map((option, index) => (
                    <div
                      className="Item Head Extra Holding odd TourComp IH304525324"
                      id="IH304525327"
                      style={{ display: "block" }}
                      key={index}
                    >
                      <span className="Description">{option.title}</span>
                      <span className="Composition">
                      {option.numAdults !== 0 ? (
                        <React.Fragment>
                          {option.numAdults}x Flexi&nbsp;
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      {option.numChildren !== 0 ? (
                        <React.Fragment>
                          {option.numChildren}x Children&nbsp;
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      {option.numInfants !== 0 ? (
                        <React.Fragment>
                          {option.numInfants}x Infants&nbsp;
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      </span>
                      <span className="Price">$ 0.00 </span>
                      <span className="Status"> Holding </span>
                      <span className="Actions"></span>
                    </div>
                  ))}
                {tour.optionData?.map((option, index) => (
                  <div
                    className="Item Head Extra Holding odd TourComp IH304525324"
                    id="IH304525327"
                    style={{ display: "block" }}
                    key={index}
                  >
                    <span className="Description">
                      {option.description} &nbsp;
                      {option.numAdults !== 0 ? (
                        <React.Fragment>
                          {option.numAdults}x Flexi&nbsp;
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      {option.numChildren !== 0 ? (
                        <React.Fragment>
                          {option.numChildren}x Children&nbsp;
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      {option.numInfants !== 0 ? (
                        <React.Fragment>
                          {option.numInfants}x Infants&nbsp;
                        </React.Fragment>
                      ) : (
                        ""
                      )}
                      &nbsp; - ${option.totalTourOptionPrice}
                    </span>
                    <React.Fragment>
                      <span className="Composition"></span>
                    </React.Fragment>
                    <span className="Price">$ 0.00 </span>
                    <span className="Status"> Holding </span>
                    <span className="Actions"></span>
                  </div>
                ))}
                */}
                              <div
                                className="Item Head Sector Holding odd TourComp IH304525324"
                                id="IH304525328"
                                style={{ display: "block" }}
                              >
                                <span className="Description">
                                  Travel from{" "}
                                  {
                                    tour?.tourData?.data?.getTour?.destination
                                      ?.name
                                  }{" "}
                                  to {tour?.dropOffLocation}
                                  <br />{" "}
                                  <span className="departTime">
                                    18-Mar-2020 3:00 pm{" "}
                                  </span>
                                </span>
                                <span className="Composition">
                                  1x Flexi&nbsp;
                                </span>
                                <span className="Price"> </span>
                                <span className="Status"> Holding </span>
                                <span className="Actions"></span>
                              </div>
                            </React.Fragment>
                          )
                        })}
                      </div>
                      <div className="CartFooter">
                        <div className="TotalsHelpText">
                          <p>
                            <span style={{ fontFamily: "arial, sans-serif" }}>
                              Items in Holding status will be released when your
                              session expires unless you proceed to checkout to
                              confirm your bookings.
                            </span>
                          </p>
                        </div>
                        <div className="CartTotals">
                          {/*
            <div className="TotalTour clearfix">
              <label>Tour Price</label>
              <span> ${this.state.cost} </span>
            </div>
            <div className="TotalTour clearfix">
              <label>Extras Price</label>
              <span> ${this.state.cartOptionPrice} </span>
            </div>
            */}
                          <div className="TotalDue clearfix">
                            <label>Total Price</label>
                            <span> ${this.state.cost} </span>
                            <p className="TaxInfo clear">($ NZD incl GST)</p>
                          </div>
                        </div>
                        <div className="Actions">
                          {this.expiresAt && (
                            <span>
                              You have until{" "}
                              {moment(this.expiresAt).format("hh:MM:SS A")} to
                              book.
                            </span>
                          )}
                          <a
                            className="EmptyCart Exit"
                            onClick={this.clearCart}
                          >
                            Empty cart
                          </a>
                          {this.state?.passengerExists ? (
                            <a className="PaxDetail Edit" href="/my-details">
                              Edit Passenger Details
                            </a>
                          ) : (
                            ""
                          )}
                          <a
                            className="Checkout Submit"
                            onClick={this.submitForm}
                          >
                            Book Now <span className="right" />
                          </a>
                        </div>
                      </div>
                    </div>
                    {/* ... */}
                    <div class="clear"></div>
                  </div>
                </div>
              </div>
            </div>
          }
        />
      </>
    )
  }
}

export default Cart
